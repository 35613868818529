// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import webScrapping from './webScrapping'

const rootReducer = {
  auth,
  navbar,
  webScrapping,
  layout
}

export default rootReducer
